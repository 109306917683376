/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 3.1.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/


//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";

//Components
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/print";
@import "custom/components/preloader";

//Pages
@import "custom/pages/authentication";
@import "custom/pages/faq";


// Plugins
@import "custom/plugins/waves";
@import "custom/plugins/datatable";
@import "custom/plugins/select2";
@import "custom/plugins/slimscroll";
@import "custom/plugins/apexcharts";
@import "custom/plugins/datepicker";
@import "custom/plugins/ui-switch";

.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.form-control:disabled, .form-control[readonly], input.form-control[readonly],
.ng-select-disabled {
  opacity: 0.7;
}

.disabled .ng-select .ng-select-container, .ng-select.disabled .ng-select-container {
  color: inherit;
}
.disabled .ng-select .ng-clear-wrapper, .ng-select.disabled .ng-clear-wrapper {
  display: none;
}
.disabled .ng-select .ng-input, .ng-select.disabled .ng-input {
  display: none;
}

.btn-warning, .btn-warning:hover, .btn-warning:active, .btn-warning:focus,
.btn-warning:not(:disabled):not(.disabled):active {
    color: $gray-800;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: $gray-600;
  font-weight: normal;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled .row {
  color: $gray-900;
  opacity: 0.6;
}

.number, .percent {
  text-align: right;
}

.percent {
	color: #6d8397;
	font-weight: bold;
}
.percent::after {
  content: '%';
}
.cursor-pointer {
  cursor: pointer;
}

.starea_inregistrarii {
  width: 12em;
}

.motiv_anexa10 {
  width: 17em;
}
.motiv_anexa10 .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: fit-content;
}

.numar {
  width: 9em;
}
.rasa {
  width: 10em;
}

.w26 {
  width: 26em;
}

.sex {
  width: 1.5rem;
  font-size: 0.75rem;
  text-align: center;
  color: var(--white);
  background-color: var(--secondary);
}


// arbore genealogic
.node-header, .node-content {
  padding: 0.5em 0.7em;
}
.node-header {
  background-color: #495ebb;
  color: #ffffff;
}
.node-content {
  text-align: center;
  border: 1px solid #495ebb;
}

// mascul, femela
.box-mascul, .box-femela {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  color: #fff;
  padding: 0 3px;
  width: 1.5em;
  margin-right: 0.5em;
}
.box-mascul, .bg-mascul {
  background-color: #1c0c8c;
}

.box-femela, .bg-femela {
  background-color: #6c2282;
}

.color-mascul {
  color: #1c0c8c;
}
.color-femela {
  color: #6c2282;
}
.color-mascul, .color-femela {
  font-weight: bold;
}
.ng-option-disabled {
  .color-mascul, .color-femela {
    font-weight: normal;
  }
}


.separator {
  background-color: #dcdee3;
  height: 1px;
  width: 100%;
}

.fs-1 {
  font-size: $h1-font-size;
}
.fs-2 {
  font-size: $h2-font-size;
}
.fs-3 {
  font-size: $h3-font-size;
}
.fs-4 {
  font-size: $h4-font-size;
}
.fs-5 {
  font-size: $h5-font-size;
}
.fs-6 {
  font-size: $h6-font-size;
}
