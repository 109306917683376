//
// topbar.scss
//

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  span.logo-lg {
    display: block;
  }
  span.logo-sm {
    display: none;
  }
  .logo-lg-text-dark {
    color: $dark;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }
  .logo-lg-text-light {
    color: $white;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }
  img {
    height: $topbar-height;
  }
}

.logo-box {
  height: $topbar-height;
  width: $leftbar-width;
  float: left;
}

.navbar-custom {
  background-color: $bg-topbar-dark;
  box-shadow: $shadow;
  padding: 0 10px 0 0;
  position: fixed;
  left: 0;
  right: 0;
  height: $topbar-height;
  z-index: 100;

  .topnav-menu {
    > li {
      float: left;
    }
    .nav-link {
      padding: 0 15px;
      color: rgba($white, 0.6);
      min-width: 32px;
      display: block;
      line-height: $topbar-height;
      text-align: center;
      max-height: $topbar-height;
    }
  }
  .dropdown.show {
    .nav-link {
      background-color: rgba($white, 0.05);
    }
  }

  /* Search */
  .app-search {
    overflow: hidden;
    height: $topbar-height;
    display: table;
    max-width: 180px;
    margin-right: 20px;

    .app-search-box {
      display: table-cell;
      vertical-align: middle;

      input::-webkit-input-placeholder {
        font-size: 0.8125rem;
        color: rgba($white, 0.3);
      }
    }
    .form-control {
      border: none;
      height: 38px;
      padding-left: 20px;
      padding-right: 0;
      color: $white;
      background-color: rgba($white, 0.07);
      box-shadow: none;
      border-radius: 30px 0 0 30px;
    }
    .input-group-append {
      margin-left: 0;
      z-index: 4;
    }

    .btn {
      background-color: rgba($white, 0.07);
      border-color: transparent;
      color: rgba($white, 0.3);
      border-radius: 0 30px 30px 0;
      box-shadow: none !important;
    }
  }

  .button-menu-mobile {
    border: none;
    color: $white;
    display: inline-block;
    height: $topbar-height;
    line-height: $topbar-height;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
  }

  .button-menu-mobile.disable-btn {
    display: none;
  }
}

.profile-dropdown {
  width: 170px;
  i {
    vertical-align: middle;
    margin-right: 5px;
  }
}

.menu-dropdown {
  width: 170px;
  margin-left: 100px;
}

.nav-user {
  padding: 0 12px !important;
  img {
    height: 32px;
    width: 32px;
  }
}

// Topbar light
.topbar-light .navbar-custom {
  background-color: $bg-topbar-light !important;
  border-bottom: 1px solid #f1f5f7;
  color: black !important;

  .topnav-menu {
    .nav-link {
      color: $gray-700;
    }
  }

  .dropdown.show {
    .nav-link {
      background-color: rgba($dark, 0.03);
    }
  }

  .button-menu-mobile {
    color: $dark;
  }

  /* Search */
  .app-search {
    input::-webkit-input-placeholder {
      color: $gray-500 !important;
    }
    .form-control {
      color: $dark;
      background-color: $gray-100;
      border-color: $gray-100;
    }
    .btn {
      background-color: $gray-100;
      color: $gray-400;
    }
  }
}

.top-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px 200px;
  min-height: $topbar-height;
}
.top-links a {
  display: inline-flex;
  color: #ffffff;
	margin-right: 1.5em;
  white-space: nowrap;
}
.top-links a:hover {
  color: #ffffff;
}


.dropdown-notification {
  width: calc(100vw - 300px);
  max-width: 50rem;

  .dropdown-item {
    padding: 0 1em;
  }

  .dropdown-item.dropdown-notification-title {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    color: #272e37;
  }
  .dropdown-item.dropdown-notification-title:hover {
    background-color: initial;
  }

  .dropdown-notification-scroll {
    max-height: calc(95vh - 75px);
    overflow: auto;
  }

  a, a:hover {
    display: block;
    color: #323a46;
    white-space: normal;
  }
}

@include media-breakpoint-down(sm) {
  .dropdown-notification {
    width: calc(100vw - 140px);
  }
}
