//
// tables.scss
//


//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    td, th, thead th {
        padding: 0.7rem 0.4rem;
        vertical-align: middle;
    }

    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

.table-spinner {
  position: absolute;
  left: 0;
  right: 0;
	display: inline-block;
	width: 4.5rem;
	height: 4.5rem;
  margin: 90px auto;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border 0.75s linear infinite;
	animation: spinner-border 0.75s linear infinite;
}

.table .action-buttons {
    padding: 0.5rem 0.8rem;
}
.table .action-buttons button {
    margin-right: 0.75rem;
}
