//
// slimscroll.scss
//
.slimscroll-menu {
  height: 100%;
}
.slimScrollDiv {
  height: auto !important;
}

.scrollbar-track {
  background: transparent !important;
}

.scrollbar-thumb {
  width: 5px !important;
  background: rgb(158, 165, 171) !important;
}
