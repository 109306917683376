//
// backgrounds.scss
//


@each $color_name, $color_value in $theme-colors {
  .bg-soft-#{$color_name} {
      background-color: rgba(($color_value), 0.25);
  }
}
