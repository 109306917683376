//
// dropdown.scss
//

.dropdown-menu {
  box-shadow: $box-shadow;
  animation-fill-mode: both;
  margin: 0;
  border: none;
  font-size: $font-size-base;
  z-index: 1000;
}
