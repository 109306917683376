//
// modal.scss
//

// Title text within header
.modal-title {
  margin-top: 0;
}

.modal-full > .modal-dialog {
  width: 92% !important;
  max-width: 100%;
}

.modal-half-full > .modal-dialog {
  width: 70% !important;
  max-width: 100%;

  @media screen and (max-width: 575px) {
    margin: 2% auto;
    width: 75% !important;
  }
}

.modal {
  z-index: 1060;
}

.modal:nth-of-type(even) {
  z-index: 1062 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1061 !important;
}


// Custom animation modal
.modal-demo {
  width: 600px !important;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  .close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: $light;
  }
}

@media (max-width: 768px) {
  .modal-demo {
    width: 96% !important;
  }
}

.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: $primary;
  color: $white;
  text-align: left;
  margin: 0;
}

.custom-modal-text {
  padding: 20px;
}

.custombox-modal-wrapper {
  text-align: left;
}

.custombox-modal-flash,
.custombox-modal-rotatedown {
  .close {
    top: 20px;
    z-index: 9999;
  }
}
