//
// datepicker.scss
//

ngb-datepicker {
    background-color: $dropdown-bg;
    position: absolute;
    z-index: $zindex-dropdown;
  }

  ngb-datepicker-navigation-select > select {
    display: inline-block;
    width: auto;
    height: calc(1.5em + 0.9rem + 2px);
    padding: 0.45rem 1.9rem 0.45rem 0.9rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    appearance: none;
  }

  ngb-datepicker-navigation-select > select:not(:first-child) {
    margin-left: 1rem;
  }

  .ngb-dp-day,
  .ngb-dp-today {
    outline: none !important;
    .btn-light,
    .btn-primary {
      box-shadow: none !important;
      outline: none !important;
      border-radius: 50%;
    }
  }

  .ngb-tp {
    box-shadow: $box-shadow;
    background-color: $dropdown-bg;
    margin-top: 5px;
    float: left;
    min-width: 10rem;
    padding: 0.75rem;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    // color: $dropdown-color;
    text-align: left;
    list-style: none;
    background-clip: padding-box;
    border: $dropdown-border-width solid $dropdown-border-color;
    border-radius: $dropdown-border-radius;
    position: absolute;
    z-index: $zindex-dropdown;
  }

  // Date Range
  .custom-day {
    line-height: 2rem;
    border-radius: 50%;
    padding: 0 !important;
    outline: none !important;

    &:hover {
      background-color: $primary !important;
    }

    &.range {
      background-color: $primary !important;
    }

    &.range.faded {
      background-color: lighten($gray-300, 5%) !important;
      color: $gray-700 !important;
    }
  }
